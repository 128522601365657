import { useState, useCallback, useRef } from "react";

type ModalKeys = string;

export type ModalsState<T extends ModalKeys> = {
  [key in T]: boolean;
};

type UseModalsResult<T extends ModalKeys> = {
  modals: ModalsState<T>;
  toggleModal: (key: T, value?: boolean) => void;
  switchModals: (key1: T, key2: T, timeOut?: number) => void;
  toggleRef: React.MutableRefObject<(key: T, value?: boolean) => void>;
};

function useModals<T extends ModalKeys>(keys: T[]): UseModalsResult<T> {
  const switchModals = (key1: T, key2: T, timeOut = 500) => {
    if (key1 === key2) {
      return;
    }
    const key1IsOpen = modals[key1];

    toggleModal(key1IsOpen ? key1 : key2, false);
    setTimeout(() => {
      toggleModal(key1IsOpen ? key2 : key1, true);
    }, timeOut);
  };

  const initialModalsState: ModalsState<T> = keys.reduce((acc, key) => {
    acc[key] = false;
    return acc;
  }, {} as ModalsState<T>);

  const [modals, setModals] = useState<ModalsState<T>>(initialModalsState);

  const toggleModal = useCallback((key: T, value?: boolean) => {
    setModals((prevModals) => ({
      ...prevModals,
      [key]: value !== undefined ? value : !prevModals[key],
    }));
  }, []);
  const toggleRef = useRef(toggleModal);

  return { modals, toggleModal, switchModals, toggleRef };
}

export { useModals };
